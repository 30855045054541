@import "./sass/responsive";

#root {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    z-index: -1;
}

.App {
    padding: 0 4rem;

    @include respond(tablet-port) {
        padding: 0;
    }
}

.ui.popup.nav-notifications {
    &--desktop {
        &.visible {
            @include respond(tablet-port) {
                display: none !important;
            }
        }
    }
    &--mobile {
        &.visible {
            display: none !important;
            @include respond(tablet-port) {
                display: block !important;
            }
        }
    }
}

.ui.segment.nav.top-nav {
    background: rgba(5, 5, 5, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin: 0;

    .navbar {
        padding: 0 4rem;
        border: none !important;
        margin: 0;

        &--desktop {
            @include respond(tablet-port) {
                display: none;
            }
        }

        &--mobile {
            display: none;
            @include respond(tablet-port) {
                display: flex;
            }
        }

        .nav-header {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .mobile-tabs {
            padding: 0;
            background: rgba(5, 5, 5, 0.8);
            position: absolute;
            left: -1rem;
            margin: 0;
            margin-top: -1.6rem;
            height: 100vh;
            z-index: 1000;
            transition: 0.3s;
            transform: translateX(-150%);
            opacity: 0;
            font-size: 1.1rem;
            min-width: 20rem;

            &--visible {
                transform: translateX(0);
                opacity: 1;
            }

            .tab-row {
                padding: 0.5rem;

                .tab {
                    padding: 10px 0;
                }

                &:not(:last-child) {
                    margin-bottom: 2rem;
                }
            }

            @include respond(tablet-port){

                .tab-row {
                    .tab {
                        display: inline;
                    }
                }
            }

            @include respond(phone) {
                width: 100vw;
                font-size: 1.5rem;

                .tab-row {
                    text-align: center;

                    &:first-child {
                        margin-top: 3rem;
                    }

                    &:not(:last-child) {
                        margin-bottom: 3rem;
                    }
                }
            }

        }

        .nav-button {
            background: transparent;
            color: white;
            border: 1px solid white;
            top: 50%;
            translate: transformY(-50%);
        }
    }
}

.ui.form.login-form,
.ui.form.signup-form {
    .field {
        & > label {
            color: white;
        }
    }
}

.ui.form.signup-form {
    background: rgba(5, 5, 5, 0.5);
    padding: 1.5rem;
    position: relative;
    width: 90%;
    max-width: 70rem;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 15rem;
}
.ui.modal.login-modal {

    .ui.form.login-form {
        .field {
            & > label {
                color: inherit;
            }
        }
    }

    .ui.divider.login-divider {
        color: inherit;
        &::after {
            background-color: inherit;
        }
    }

    .ui.divider.login-divider--mobile {
        color: inherit;
        border: none;

        &::before, &::after {
            background-color: rgba(34, 36, 38, 0.15);
            height: 1px;
        }
    }
}

.ui.divider.login-divider {
    color: white;
    &::after {
        background-color: white;
    }
    @media only screen and (max-width: 767px) {
        display: none !important;
    }
}

.ui.divider.login-divider--mobile {
    color: white;
    text-align: center;
    margin: 1rem auto;
    margin-bottom: 2rem;
    display: none;

    @media only screen and (max-width: 767px) {
        display: block;
    }

    &::before, &::after {
        content: "";
        position: relative;
        display: block;
        width: 500%;
        height: 2px;
        background-color: white;
    }

    &::before {
        left: -550%;
        top: 7px;
    }
    &::after {
        left: 150%;
        top: -6px;
    }
}

.ui.card.dashboard-card {
    width: 100%;

    &--loading {
        .content {
            border: none !important;
        }
    }
}

.card-loader {
    position: relative !important;
    margin-top: 5rem;
}

.dashboard-card .ui.statistic {
    @include respond(tablet-land) {
        font-size: 1rem !important;
    }

    & > .value {
        @include respond(tablet-land) {
            font-size: 3rem !important;
        }
        @include respond(tablet-port) {
            font-size: 2.5rem !important;
        }
    }
}

.ui.container.ml-info {
    background: rgba(5, 5, 5, 0.7);
    color: white;
    padding: 4rem;
    margin: 0 auto !important;
    margin-bottom: 5rem !important;

    @include respond(tablet-port) {
        padding: 2rem;
    }
    @media only screen and (max-width: 767px) {
        margin-top: -3rem !important;
    }
}

.disabled-accordion-title:hover {
    color: rgba(0, 0, 0, 0.4) !important;
}

.ui.segment.form-background {
    background: rgba(215, 220, 220, 0.97);
    max-width: 70rem;
    margin: 0 auto;
}

.ui.divided.items > .item.chat-message {
    position: relative;
    padding: 1rem;
}

.ui.divided.items > .item.chat-message:last-child {
    padding-bottom: 1rem !important;
}

.ui.items > .item.chat-message::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 120, 255, 0.1);
    transition: all 0.2s;
}

.ui.divided.items > .item.chat-message.new::before {
    opacity: 1;
}
